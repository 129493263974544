import { domToReact, Element } from "html-react-parser";
import type { DOMNode } from "html-react-parser";
import { parseU } from "./parseU";

export const quoteParser = (mode: "normal" | "dark") => (domNode: DOMNode) => {
  const { name, children } = domNode as Element;
  if (name === "p") {
    return <>{domToReact(children as DOMNode[], { replace: quoteParser(mode) })}</>;
  }
  if (name === "u") {
    switch (mode) {
      case "dark":
        return parseU(
          children as DOMNode[],
          { replace: quoteParser(mode) },
          "text-secondary-grapefruit"
        );
      default:
        return parseU(children as DOMNode[], { replace: quoteParser(mode) });
    }
  }
};
