import plugin  from "tailwindcss/plugin";

const borderGradientBase = plugin(function ({ addComponents }) {
  addComponents({
    ".border-gradient-base": {
      position: "relative",
      "z-index": "0",
    },
    ".border-gradient-base:before": {
      content: "",
      position: "absolute",
      "z-index": "-1",
      inset: "0",
      "-webkit-mask":
        "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
      mask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
      "-webkit-mask-composite": "xor",
      "mask-composite": "exclude",
    },
  });
});

export default borderGradientBase