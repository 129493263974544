import { ReactElement, ReactNode, useEffect, useState } from "react";
import * as Sentry from "@sentry/nextjs";
import type { AppContext, AppInitialProps, AppProps } from "next/app";
import App from "next/app";
import { DehydratedState, Hydrate, QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import NextNProgress from "nextjs-progressbar";
import { NextPage } from "next";
import Head from "next/head";
import { useRouter } from "next/router";
import { getCanonical } from "@/utilities/getCanonical";
import Layout from "@/components/Layout/Layout/Layout";
import { getFeatures, WithFeatures } from "@/services/features/featureService";
import tailwindConfig from "@/tailwind.config";
import resolveConfig from "tailwindcss/resolveConfig";
import {
  getLayoutContent,
  PrimaryNavigationNode,
  Footer,
  DialogForm,
  WhitepaperDialogProps,
} from "@/services/prepr/queries/getLayoutContent";
import { SentryLogger } from "@/utilities/logger";
import { preprTimoutErrorMessage } from "@/services/prepr/sdk";
import { DialogWrapper } from "@/components/Layout/useDialogForms";
import { CookieNotification } from "@/components/blocks/CookieNotification/CookieNotification";
import { useSetConsentFromStorage } from "@/components/shared/GTM/GTM";
import { isProduction } from "@/utilities/isProduction";
import usePrevious from "@/hooks/usePrevious";
import { FeatureContext } from "@/hooks/useFeature";
import { getRobotsMetaContent } from "@/utilities/getRobotsMetaContent";

import "../styles/globals.css";

const { theme } = resolveConfig(tailwindConfig) as any;

type LayoutProps = {
  navigation: PrimaryNavigationNode[];
  dialogs: DialogForm[];
  whitepaperDialog?: WhitepaperDialogProps | null;
  footer: Footer;
};

interface MetaProps extends Partial<DehydratedState> {
  robots___noindex?: boolean;
  robots___nofollow?: boolean;
  canonical_url?: string;
}

type Props = WithFeatures & {
  pageProps: {
    [key: string]: MetaProps;
  };
  layoutProps: LayoutProps;
};

export type NextPageWithLayout<P = unknown, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement, layoutProps: LayoutProps) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({
  Component,
  pageProps,
  features,
  layoutProps,
  isProduction,
}: Props & AppPropsWithLayout) {
  useSetConsentFromStorage();

  const router = useRouter();
  const prevRoute = usePrevious(router.asPath);

  // needed for successfully loading the hello rider widgets on the /fiets slugs
  useEffect(() => {
    if (!router.asPath.includes("fiets")) return;

    if (prevRoute && prevRoute !== router.asPath) {
      router.reload();
    }
  }, [prevRoute, router.asPath]);

  const [queryClient] = useState(() => new QueryClient());

  const getLayout =
    Component.getLayout ??
    ((page, layoutProps) => (
      <Layout layoutProps={layoutProps}>
        <NextNProgress
          options={{ showSpinner: false }}
          color={theme.extend?.colors?.secondary?.aubergine}
        />
        {page}
      </Layout>
    ));

  // Get page settings (robots, canonical) only from specific page types
  const pageTypes = ["dynamicPage", "blogPage", "customerStoryPage", "employeeStoryPage"];
  const pageSettings: any = Object.entries(pageProps).find(([key]) => pageTypes.includes(key))?.[1];

  const robots = getRobotsMetaContent(
    pageSettings?.robots___noindex,
    pageSettings?.robots___nofollow
  );

  const canonical = getCanonical(router, pageSettings?.canonical_url);

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <FeatureContext.Provider value={features}>
          <DialogWrapper
            dialogs={layoutProps.dialogs}
            whitepaperDialog={layoutProps.whitepaperDialog}
          >
            <Head>
              {/* NOTE: this is the default 'description' and will be overriden in pages */}
              <meta
                name="description"
                content="Mobiliteit en bedrijfsmiddelen voor ondernemend Nederland"
              />
              <link rel="icon" href="/favicon.ico" />
              {/* TODO: remove robots based on isProduction, once we have IP restrictions in place for OTA environments */}
              {!isProduction ? <meta name="robots" content="noindex, nofollow" /> : <></>}
              {isProduction && robots ? <meta name="robots" content={robots} /> : <></>}
              {canonical ? <link rel="canonical" href={canonical} /> : <></>}
            </Head>
            {getLayout(<Component {...pageProps} />, layoutProps)}
            <CookieNotification />
          </DialogWrapper>
        </FeatureContext.Provider>
        <ReactQueryDevtools initialIsOpen={false}></ReactQueryDevtools>
      </Hydrate>
    </QueryClientProvider>
  );
}

MyApp.getInitialProps = async (appContext: AppContext): Promise<Props & AppInitialProps> => {
  const [appProps, layoutContent, features] = await Promise.all([
    App.getInitialProps(appContext),
    getLayoutContent().catch((e: any) => {
      SentryLogger.logError(
        e.message === preprTimoutErrorMessage
          ? e
          : new Error("Something went wrong while resolving getLayoutContent", { cause: e })
      );
      return null;
    }),
    getFeatures(),
  ]);
  if (!layoutContent) {
    Sentry.captureMessage("Layout content was empty at _app.getInitialProps", "warning");
  }
  const navigation = layoutContent?.PrimaryNavigationNodes?.items;
  const footer = layoutContent?.Footer;
  const dialogs = layoutContent?.DialogForms?.items;
  const whitepaperDialog = layoutContent?.WhitepaperDialog as WhitepaperDialogProps;
  if (layoutContent) {
    if (!navigation || navigation.length === 0) {
      Sentry.captureMessage("Navigation was empty at _app.getInitialProps", "warning");
    }
    if (!footer) {
      Sentry.captureMessage("Footer does not exist (_app.getInitialProps)", "warning");
    }
    if (!dialogs || dialogs.length === 0) {
      Sentry.captureMessage("DialogForms were empty at _app.getInitialProps", "warning");
    }
  }

  return {
    ...appProps,
    features: features,
    isProduction: isProduction(),
    layoutProps: {
      navigation: navigation || [],
      dialogs: dialogs || [],
      whitepaperDialog: whitepaperDialog,
      footer,
    },
  };
};

export default MyApp;
