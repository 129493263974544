import { domToReact, Element } from "html-react-parser";
import type { DOMNode } from "html-react-parser";
import Link from "next/link";
import { parseU } from "./parseU";

export const textParser = (mode: "normal" | "dark") => (domNode: DOMNode) => {
  const options = { replace: textParser(mode) };
  const { name, children, attribs } = domNode as Element;

  switch (name) {
    case "p":
      return <p className="mb-4">{domToReact(children as DOMNode[], options)}</p>;
    case "br":
      return (
        <>
          <br />
          {domToReact(children as DOMNode[], options)}
        </>
      );
    case "a":
      return (
        <Link
          href={attribs.href || ""}
          className={`cursor-pointer ${
            mode == "normal" ? "text-secondary-aubergine" : ""
          } underline`}
          {...attribs}
        >
          {domToReact(children as DOMNode[], options)}
        </Link>
      );
    case "u":
      return parseU(children as DOMNode[], options);
    case "ul":
      return (
        <ul className="list-inside list-disc">{domToReact(children as DOMNode[], options)}</ul>
      );
    case "strong":
    case "b":
      return (
        <strong className="font-sans font-bold">
          {domToReact(children as DOMNode[], options)}
        </strong>
      );
  }
};
