import Image from "next/legacy/image";
import Link from "next/link";
import ChevronRight from "@/public/icons/chevron-right.svg";
import { NavbarType } from "../../Navbar";
import { HoverTarget } from "../NavbarDesktop";

interface Props {
  closeMenu: () => void;
  setSubMenu: (menu: HoverTarget) => void;
  aboutHasMenu: boolean;
  serviceHasMenu: boolean;
  type: NavbarType;
}

const MainMenuLi = ({ children }: { children: React.ReactNode }) => (
  <li
    className="before:from-secondary-aubergine relative mx-8 before:absolute before:bottom-0
  before:h-[2px] before:w-full before:bg-gradient-to-r before:to-[rgba(0,0,0,0)] before:content-['']"
  >
    {children}
  </li>
);

const MainMenuButton = ({ name, onClick }: { name: string; onClick: () => void }) => (
  <MainMenuLi>
    <button
      className="text-menu-sm flex w-full items-center justify-between py-6 font-bold"
      onClick={onClick}
      data-gtm-id="main_navigation"
    >
      <span>{name}</span>
      <Image src={ChevronRight} alt="Chevron icon" width="8"></Image>
    </button>
  </MainMenuLi>
);

const MainMenuLink = ({
  name,
  href,
  onClick,
}: {
  name: string;
  href: string;
  onClick: () => void;
}) => (
  <MainMenuLi>
    <Link
      href={href}
      className="text-menu-sm flex w-full items-center justify-between py-6 font-bold"
      onClick={onClick}
      data-gtm-id="main_navigation"
    >
      {name}
    </Link>
  </MainMenuLi>
);

const MainMenu: React.FC<Props> = ({
  setSubMenu,
  closeMenu,
  aboutHasMenu,
  serviceHasMenu,
  type,
}) => {
  const setAutoLease = () => setSubMenu("autolease");
  const setEquipmentLease = () => setSubMenu("equipment-lease");
  const setVoorraadFinanciering = () => setSubMenu("voorraadfinanciering");
  const setAboutUs = () => setSubMenu("over-ons");
  const setService = () => setSubMenu("service");

  return (
    <div data-testid="main-menu" className="bg-primary-black relative h-full">
      <ul>
        {type === "default" ? (
          <>
            <MainMenuButton name="Autolease" onClick={setAutoLease} />
            <MainMenuButton name="Equipment Lease" onClick={setEquipmentLease} />
            <MainMenuButton name="Voorraad financiering" onClick={setVoorraadFinanciering} />
          </>
        ) : (
          <>
            <MainMenuButton name="Fiets aanbod" onClick={() => setSubMenu("fiets-aanbod")} />
            <MainMenuButton name="Calculator" onClick={() => setSubMenu("calculator")} />
            <MainMenuButton name="Vind een dealer" onClick={() => setSubMenu("vind-dealer")} />
          </>
        )}
        {type === "default" && (
          <>
            {aboutHasMenu ? (
              <MainMenuButton name="Over ons" onClick={setAboutUs} />
            ) : (
              <MainMenuLink name="Over ons" href="/over-ons" onClick={closeMenu} />
            )}
          </>
        )}
        {serviceHasMenu ? (
          <MainMenuButton name="Service & contact" onClick={setService} />
        ) : (
          <MainMenuLink name="Service & contact" href="/service-contact" onClick={closeMenu} />
        )}
      </ul>
    </div>
  );
};

export default MainMenu;
