import clsx from "clsx";
import { ChangeEvent, HTMLInputTypeAttribute, RefObject } from "react";
import { RefCallBack } from "@hiltermann/components/react-hook-form";

interface Props {
  testId?: string;
  label?: string;
  name?: string;
  value: string | number;
  type?: HTMLInputTypeAttribute;
  placeholder?: string;
  onChange: (v: string) => void;
  onBlur?: (v: string) => void;
  onClick?: () => void;
  error?: React.ReactNode;
  success?: React.ReactNode;
  inputClass?: string;
  className?: string;
  inputRef?: RefObject<HTMLInputElement> | RefCallBack;
  disabled?: boolean;
  inputPrefix?: React.ReactNode;
}

// todo: format label to lowercase and kebab for test ids
// note that this will most likely require a lot of tests to be refactored

const FormInput: React.FC<Props> = ({
  testId,
  label,
  value,
  type,
  placeholder,
  onChange,
  onBlur,
  onClick,
  error,
  success,
  inputClass,
  className,
  inputRef,
  name,
  disabled = false,
  inputPrefix,
}) => {
  const handleChange: (event: ChangeEvent<{ value: string }>) => void = (event) =>
    onChange(event.target.value);
  const handleBlur: (event: ChangeEvent<{ value: string }>) => void = (event) =>
    onBlur ? onBlur(event.target.value) : null;
  const errorClass = error
    ? "border-secondary-aubergine text-secondary-aubergine"
    : "border-grey-asphalt text-primary-black";
  const errorTestId = `error-${testId ?? label?.toLowerCase()}`;
  return (
    <div className={`${className ?? ""}`}>
      {label && (
        <label
          htmlFor={label}
          className="text-label-xs mb-3 block font-sans font-bold tracking-wider"
        >
          {label}
        </label>
      )}
      <div className="relative">
        {inputPrefix && (
          <div className="text-label-xs text-primary-black pointer-events-none absolute left-5 top-1/2 -translate-y-1/2 font-bold">
            {inputPrefix}
          </div>
        )}
        <input
          name={name}
          ref={inputRef}
          data-testid={testId || `input-${label}`}
          id={label}
          className={clsx(
            inputPrefix ? "px-8" : "px-6",
            "text-label-xs w-full rounded-lg border py-3 font-bold placeholder:font-normal",
            "focus:outline-secondary-aubergine focus:-m-px focus:border-2",
            !disabled && "hover:-m-px hover:border-2",
            errorClass,
            inputClass
          )}
          placeholder={placeholder}
          value={value}
          type={type}
          onChange={handleChange}
          onClick={onClick}
          onBlur={handleBlur}
          disabled={disabled}
        />
      </div>
      {!success && error && (
        <div
          className="border-secondary-grapefruit text-p-sm text-secondary-aubergine -z-10 -mt-2 w-full rounded-lg border bg-white px-6 pb-4 pt-5 font-bold"
          data-testid={errorTestId}
        >
          {error}
        </div>
      )}
      {success && (
        <div className="text-p-sm text-primary-emerald -z-10 -mt-2 w-full rounded-b-lg bg-[#D7FFFB] px-6 pb-4 pt-5 font-bold">
          {success}
        </div>
      )}
    </div>
  );
};

export default FormInput;
