// Colors and typography come from:

import type { Config } from "tailwindcss/types/config";

// https://www.figma.com/file/RN29efKLgTrBMRSWYnJQf2/Hiltermann-Lease-Design-Library?node-id=922%3A2969
const presets: Partial<Config> = {
  safelist: ["w-1/2"],
  theme: {
    screens: {
      mobile: "359px",
      tablet: "775px",
      "desktop-s": "1183px",
      "desktop-xl": "1472px",
    },
    colors: {
      transparent: "transparent",
      black: "#000000",
      white: "#FFFFFF",
      primary: {
        black: "#0B1423",
        emerald: "#265E79",
        airforce: "#54798C",
        mint: "#03DAC5",
      },
      secondary: {
        aubergine: "#7C394A",
        burgundy: "#9A455A",
        grapefruit: "#D87585",
        pink: "#FFC2CC",
      },
      grey: {
        dark: "#7F7E7E",
        asphalt: "#C6C2C2",
        dolphin: "#E8E4E3",
        light: "#F3F3F3",
      },
      signal: {
        red: "#DA3B3F",
        yellow: "#FFD23D",
        orange: "#FCA43A",
        green: "#3EBE89",
        moneyGreen: "#e4f6ef",
      },
      notification: {
        warning: "#FFECAC",
        success: "#D7FFFB",
        error: "#FFEAED",
      },
    },

    extend: {
      animation: {
        "bounce-in": "bounce-in 0.4s, ease-in",
        "fade-in-bg": "fade-in-bg 1s, ease-in",
        "slide-in": "slide-in 0.2s ease-out",
        "slide-out": "slide-out 0.2s ease-in",
        "slide-up": "slide-up 0.3s ease-out",
        "gradient-x": "gradient-x 3s ease infinite",
      },
      backgroundImage: {
        "gradient-burgundy-to-emerald-top-half":
          "linear-gradient(150deg, rgba(154,69,90,1) 0%, rgba(11,20,35,1) 25%, rgba(38,94,121,1) 100%)",
        "burgundy-fade":
          "linear-gradient(90deg, rgba(154,69,90,1) 0%, rgba(255,255,255,0.15) 75%, rgba(255,255,255,0) 100%)",
      },
      backgroundPosition: {
        "right-4": "center right 1rem",
      },
      boxShadow: {
        // DEFAULT: '2px 2px 4px rgba(144,64,85,0.82)',
        button: "2px 2px 4px rgba(144,64,85,0.82)",
        cardHoverShadow: "0px 8px 68px rgba(0, 0, 0, 0.04)",
        // submenu: 'inset 0 4px 16px 0 rgb(11 20 35 / 0.12)',
        none: "none",
      },
      dropShadow: {
        xl: "0px 4px 40px rgba(2, 2, 2, 0.08)",
      },
      fontFamily: {
        cursive: ["Caveat", "cursive"],
        sans: ["Manrope", "sans-serif"],
        serif: ["Adamina", "serif"],
      },
      fontSize: {
        h1: ["4.5rem", { lineHeight: "5.5rem" }],
        "h1-md": ["4rem", { lineHeight: "5.1rem" }],
        "h1-sm": ["2.5rem", { lineHeight: "3rem" }],
        h2: ["3.5rem", { lineHeight: "4rem" }],
        "h2-sm": ["1.875rem", { lineHeight: "2.375rem" }],
        h3: ["3rem", { lineHeight: "3.5rem" }],
        "h3-sm": ["1.5rem", { lineHeight: "2rem" }],
        h4: ["2.5rem", { lineHeight: "3.5rem" }],
        "h4-sm": ["1.25rem", { lineHeight: "1.75rem" }],
        "h5-sm": ["1rem", { lineHeight: "1.5rem" }],
        intro: ["1.25rem", { lineHeight: "2.5rem" }],
        "intro-l": ["2rem", "2rem"],
        p: ["1rem", { lineHeight: "2rem" }],
        "p-sm": ["0.875rem", { lineHeight: "1.5rem" }],
        "p-xs": ["0.75rem", { lineHeight: "1.125rem" }],
        "subline-sm": ["0.875rem", { lineHeight: "1rem" }],
        "subline-xs": ["0.75rem", { lineHeight: "0.875rem" }],
        forms: ["1.125rem", { lineHeight: "1.5rem" }],
        "forms-sm": ["0.75rem", { lineHeight: "1.5rem" }],
        "forms-md": ["1rem", "1.5rem"],
        label: ["1.125rem", { lineHeight: "1.5rem" }],
        "label-sm": ["1rem", { lineHeight: "1.5rem" }],
        "label-xs": ["0.875rem", { lineHeight: "1.375rem" }],
        menu: ["1.25rem", { lineHeight: "1.5rem" }],
        "menu-sm": ["1rem", { lineHeight: "1.5rem" }],
        button: ["1.25rem", { lineHeight: "1.5rem" }],
        "button-sm": ["1rem", { lineHeight: "1.5rem" }],
      },
      fontWeight: {
        bold: "700",
        light: "300",
        normal: "400",
        semibold: "600",
      },
      gridTemplateColumns: {
        "card-grid": "repeat(2, minmax(0, 448px))",
      },
      height: {
        halfscreen: "50vh",
      },
      keyframes: {
        "gradient-x": {
          "0%, 100%": {
            "background-size": "200% 200%",
            "background-position": "left center",
          },
          "50%": {
            "background-size": "200% 200%",
            "background-position": "right center",
          },
        },
        "bounce-in": {
          "0%": {
            transform: "scale(0)",
          },
          "70%": {
            transform: "scale(1.05)",
          },
          "90%": {
            transform: "scale(.95)",
          },
          "100%": {
            transform: "scale(1)",
          },
        },
        "fade-in-bg": {
          "0%": {
            opacity: "0",
          },
          "100%": {
            opacity: "0.8",
          },
        },
        "slide-in": {
          "0%": {
            transform: "translate(-100%, 0)",
          },
          "100%": {
            transform: "translate(0, 0)",
          },
        },
        "slide-out": {
          "0%": {
            transform: "translate(0, 0)",
          },
          "100%": {
            transform: "translate(-100%, 0)",
          },
        },
        "slide-up": {
          "0%": {
            transform: "translate(0, 100%)",
          },
          "70%": {
            transform: "translate(0, -10%)",
          },
          "90%": {
            transform: "translate(0, 5%%)",
          },
          "100%": {
            transform: "translate(0, 0)",
          },
        },
      },
      letterSpacing: {
        normal: "0em",
        wide: "0.02em",
      },
      maxWidth: {
        "desktop-xl": "1408px",
      },
      spacing: {
        18: "72px",
        26: "104px",
        28: "112px",
      },
    },
  },
};

export default presets;
